import React from "react"
import { Box, Text, Grid, Heading, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"

const DoubleColumnList = ({ models }) => {
  return (
    <Box sx={{ py: [4, 6], pt: [0, 0] }}>
      <Container>
        <Grid columns={["1fr", "1fr 1fr"]}>
          {models.map(model => (
            <Box>
              <Text variant="kicker">{model.kicker}</Text>
              <Heading variant="h3" as="h2" sx={{ fontWeight: "500", mb: [4] }}>
                {model.title}
              </Heading>
              <RichContentStructuredText text={model.body} />
              <Box
                className="bg-image"
                sx={{
                  gridRow: [1, 1, "auto"],
                  zIndex: 1,
                  ".gatsby-image-wrapper": {
                    width: "100%",
                    img: {
                      width: "auto",
                      height: "auto",
                    },
                  },
                  overflow: "hidden",
                }}
              >
                {model.image && (
                  <GatsbyImage
                    image={model.image.gatsbyImageData}
                    alt={model.image.gatsbyImageData}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default DoubleColumnList
