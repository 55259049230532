import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Flex, Heading } from "theme-ui"
import Breadcrumbs from "../components/breadcrumbs"
import BlockParallax from "../components/blockParallax"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const PageHero = ({ page, image }) => {
  const index = useBreakpointIndex()

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "light",
        mb: [0, 0, 0, 4],
        mt: [7, 6, 0, 0],
      }}
    >
      <Container variant="customFW" sx={{ position: "relative" }}>
        <Box sx={{ position: "relative" }}>
          <Flex
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{ ml: 5 }}>
              <Heading
                as="h1"
                variant="h1"
                color="light"
                sx={{ wordBreak: "break-word" }}
              >
                {page.title}
              </Heading>
              <Breadcrumbs page={page} />
            </Box>
          </Flex>
          <Box
            sx={{
              ".gatsby-image-wrapper": { height: "100%" },
            }}
          >
            {image ? (
              <GatsbyImage
                image={image.desktop}
                alt={index > 2 ? image.desktop : image.mobile}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  minHeight: "890px",
                  backgroundColor: "secondaryLight",
                }}
              ></Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default PageHero
