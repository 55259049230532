import React, { useState } from "react"
import { Box, Text, Grid, Flex, Container, Heading } from "@theme-ui/components"
import Collapsible from "react-collapsible"
import { Plus } from "react-feather"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"

const Accordion = ({ title, items }) => {
  const primary = getColor(themeUiTheme, "primary")
  const secondary = getColor(themeUiTheme, "secondary")
  const [tabIndex, setTabIndex] = useState(0)

  const _renderTrigger = (text, body) => {
    return (
      <Flex
        className="Collapsible__trigger__label"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          color: "text",
        }}
      >
        <Flex
          sx={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Heading as="h2" variant="h3" sx={{ my: [0] }}>
            {text}
          </Heading>
        </Flex>
        <Flex
          className="chevro-wrapper"
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          {body.value.document.children[0].children[0].value && <Plus />}
        </Flex>
      </Flex>
    )
  }

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Container>
          <Box
            sx={{
              my: [4],
              ".Collapsible": {
                borderRadius: "sm",
              },
              ".Collapsible__contentInner": {
                padding: [0, 0],
              },
              ".Collapsible__contentInner_p": {
                marginBottom: "10px",
                fontSize: "14px",
                lineHeight: "20px",
              },
              ".Collapsible__contentInner_p_last_child": {
                marginBottom: "0",
              },
              ".Collapsible__trigger": {
                cursor: "pointer",
                borderBottom: "1px solid",
                borderColor: "Grey",
                display: "block",
                fontWeight: "400",
                textDecoration: "none",
                position: "relative",
                py: [3],
                px: [0],
                // "background": "lightGrey",
                color: "text",
                ".Collapsible__trigger__label": {
                  fontWeight: "bold",
                  ".chevro-wrapper svg": {
                    transition: ".3s" /* Transition duration */,
                  },
                },
                "&.is-open": {
                  ".Collapsible__trigger__label": {
                    h2: {
                      color: "secondary",
                    },
                  },
                  ".Collapsible__trigger__label .chevro-wrapper svg": {
                    h2: {
                      color: "red",
                    },
                    transform: "rotate(225deg)",
                  },
                },
              },
              ".Collapsible__trigger_after": {
                fontFamily: "'FontAwesome'",
                content: "'\\f107'",
                position: "absolute",
                right: "10px",
                top: "10px",
                display: "block",
                transition: "transform 300ms",
              },
              ".Collapsible__trigger_is_open_after": {
                transform: "rotate(225deg)",
              },
              ".Collapsible__trigger_is_disabled": {
                opacity: "0.5",
                backgroundColor: "grey",
              },
              ".CustomTriggerCSS": {
                backgroundColor: "lightcoral",
                transition: "background-color 200ms ease",
              },
              ".CustomTriggerCSS__open": {
                backgroundColor: "darkslateblue",
              },
              ".Collapsible__custom_sibling": {
                padding: "5px",
                fontSize: "12px",
                backgroundColor: "#cbb700",
                color: "text",
              },
              ".grid-box": {
                gridGap: [0],
                py: [2],
                borderRadius: "sm",
                ".&:nth-child(even)": {
                  backgroundColor: "lightGrey",
                },
                "& div:first-child": {
                  color: "secondaryText",
                },
                "& div:nth-child(2)": {
                  fontWeight: "bold",
                },
              },
            }}
          >
            {items.map(item => (
              <Collapsible
                transitionTime={200}
                trigger={_renderTrigger(item.title, item.body)}
              >
                {item.body &&
                  item.body.value.document.children[0].children[0].value && (
                    <Grid
                      columns={["1fr", "1fr", "1fr 1fr"]}
                      gap={[3, 8]}
                      sx={{ my: [3] }}
                    >
                      <Box
                        sx={{
                          gridRow: [2, 2, "auto"],
                          "p,h4": {
                            textDecoration: "none",
                            "& > em": {
                              color: "secondary",
                              fontStyle: "normal",
                            },
                            u: {
                              textDecoration: "none",
                              em: {
                                color: "primary",
                                textDecoration: "none",
                                fontStyle: "normal",
                              },
                            },
                            em: {
                              textDecoration: "none",
                              u: {
                                color: "primary",
                                textDecoration: "none",
                                fontStyle: "normal",
                              },
                            },
                          },
                          "h4,h3": {
                            fontSize: [2],
                            my: [3],
                          },
                        }}
                      >
                        <RichContentStructuredText text={item.body} />
                      </Box>
                      <Box
                        className="bg-image"
                        sx={{
                          gridRow: [1, 1, "auto"],
                          zIndex: 1,
                          ".gatsby-image-wrapper": {
                            width: "100%",
                            img: {
                              width: "100%",
                              height: "auto",
                            },
                          },
                          overflow: "hidden",
                        }}
                      >
                        {item.image && (
                          <GatsbyImage
                            image={item.image.gatsbyImageData}
                            alt={item.image.gatsbyImageData}
                          />
                        )}
                      </Box>
                    </Grid>
                  )}
              </Collapsible>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Accordion
