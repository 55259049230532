import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Heading, Flex, Text } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import TitleAndBody from "../components/blocks/titleAndBody"
import PageCarousel from "../components/blocks/pageCarousel"
import Image from "../components/blocks/image"
import ImageGallery from "../components/blocks/imageGallery"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import Accordion from "../components/blocks/accordion"
import DocumentCollection from "../components/blocks/documentCollection"
import Embed from "../components/blocks/embed"
import PageHero from "./pageHero"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import ContactForm from "../components/blocks/contactFrom"
import Categories from "../components/blocks/categoryBlock"
import BackgroundImageAndBlock from "../components/blocks/backgroundImageAndBlock"
import ImageAndTextCollection from "../components/blocks/imageAndTextCollection"
import ImageAndTextCollectionCustom from "../components/blocks/imageAndTextCollectionCustom"
import ProductsGallery from "../components/blocks/productsGallery"
import HomeAnimation from "../components/homeAnimation"
import ProductTable from "../components/blocks/productTable"
import { useFavicon } from "../hooks/useFavicon"
import VerticalLines from "../components/verticalLins"
import DoubleColumnList from "../components/blocks/doubleColumnList"
import ContactFrom from "../components/blocks/contactFrom"
import ReferencesBlock from "../components/blocks/referencesBlock"
import { GatsbyImage } from "gatsby-plugin-image"
import { MagicLink } from "../utils/magicLink"
import ArrowLong from "../images/long-arrow.inline.svg"
import CatalogueDownload from "../components/blocks/catalogueDownload"

const LocationsMap = loadable(
  () => import("../components/blocks/locationMap"),
  { ssr: false }
)

const Page = ({ data: { page, site, contactSection } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "title_and_body" && (
            <TitleAndBody content={block.content} />
          )}
          {block.model.apiKey === "catalogue_download" && (
            <CatalogueDownload page={page} />
          )}
          {block.model.apiKey === "ordered_list" && (
            <OrderedList
              title={block.title}
              subtitle={block.subtitle}
              body={block.body}
            />
          )}
          {block.model.apiKey === "page_carousel" && (
            <PageCarousel title={block.title} pages={block.pages} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel items={block.items} lighter={block.lighter} />
          )}
          {block.model.apiKey === "products_gallery" && (
            <ProductsGallery products={block.products} />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion title={block.title} items={block.items} />
          )}
          {block.model.apiKey === "background_image_and_block" && (
            <BackgroundImageAndBlock
              content={block.blocks}
              align={block.align}
              color={block.color}
            />
          )}
          {block.model.apiKey === "image_and_text_collection" && (
            <ImageAndTextCollection
              items={block.collection}
              smallImage={block.smallImage}
              align={block.align}
            />
          )}
          {block.model.apiKey === "image_and_text_collection_custom" && (
            <ImageAndTextCollectionCustom
              items={block.collection}
              smallImage={block.smallImage}
              align={block.align}
            />
          )}
          {block.model.apiKey === "document_collection" && (
            <DocumentCollection
              title={block.title}
              documents={block.documents}
              showPublicationDate={block.showPublicationDate}
            />
          )}
          {block.model.apiKey === "image" && <Image image={block.image} />}
          {block.model.apiKey === "agent_collection" && (
            <LocationsMap locations={block.agents} />
          )}
          {block.model.apiKey === "embed" && (
            <Embed
              title={block.title}
              code={block.code}
              fullWidth={block.fullWidth}
            />
          )}
          {block.model.apiKey === "image_gallery" && (
            <ImageGallery images={block.images} />
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              title={block.title}
              image={block.image}
              numbers={block.numbers}
            />
          )}
          {block.model.apiKey === "table" && (
            <ProductTable
              kicker={block.kicker}
              title={block.title}
              table={block.table}
            />
          )}
          {block.model.apiKey === "contact_form" && (
            <ContactForm
              kicker={block.kicker}
              title={block.title}
              subtitle={block.subtitle}
              privacyPolicyDescription={block.privacyPolicyDescription}
              newsletterDescription={block.newsletterDescription}
            />
          )}
          {block.model.apiKey === "double_column_list" && (
            <DoubleColumnList models={block.models} />
          )}

          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              label={block.content.label}
              subtitle={block.content.subtitle}
              title={block.content.title}
              body={block.content.body}
              image={block.image}
              rightAligned={block.rightAligned}
            />
          )}
          {block.model.apiKey === "category" && (
            <Categories
              page={page}
              title={block.title}
              description={block.description}
            />
          )}
          {block.model.apiKey === "references_block" && (
            <ReferencesBlock
              title={block.category}
              references={block.referenceContent}
            />
          )}
          {block.model.apiKey === "full_image_title" && (
            <Container variant="customFW">
              <Heading
                variant="h3"
                as="div"
                sx={{
                  h2: {
                    fontSize: [4, 5],
                    fontWeight: "normal",
                  },
                  color: "secondary",
                }}
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
              <Box
                sx={{
                  position: ["relative", "relative", "relative"],
                  width: ["100%", "100%", "100%"],
                  top: 0,
                  ".gatsby-image-wrapper": {
                    height: "100%",
                  },
                }}
              >
                <GatsbyImage image={block.image.gatsbyImageData} alt="" />
              </Box>
            </Container>
          )}
        </Box>
      ))}

      {contactSection && page.showContactSection && (
        <>
          <Container
            name="contactForm"
            sx={{ py: [4, 4], position: "relative" }}
          >
            <Box>
              <Flex
                sx={{ justifyContent: "center", flexWrap: "wrap" }}
                gap={[1, 1, 1, 6]}
              >
                <Box sx={{ maxWidth: "600px" }}>
                  <Text variant="kicker" sx={{ color: "primary" }}>
                    {contactSection.nodes[0].kicker}
                  </Text>
                  <Heading
                    as="h2"
                    variant="h3"
                    sx={{
                      my: [3, 4],
                      color: "primary",
                      fontSize: [4, 4],
                      fontFamily: "body",
                      fontWeight: "300",
                      letterSpacing: ".5px",
                      lineHeight: "1.2",
                    }}
                  >
                    {contactSection.nodes[0].title}
                  </Heading>
                  <Heading
                    as="h3"
                    variant="h3"
                    sx={{
                      color: "primary",
                      fontWeight: 300,
                      my: [0, 4],
                    }}
                  >
                    {contactSection.nodes[0].subtitle}
                  </Heading>
                  <Box className="ContactFrom" sx={{ textAlign: "right" }}>
                    {contactSection.nodes[0].link && (
                      <MagicLink
                        item={contactSection.nodes[0].link}
                        variant="links.primary"
                      >
                        <Flex sx={{ alignItems: "center" }}>
                          {contactSection.nodes[0].link.anchor}
                          <Flex
                            className="arrowIcon"
                            sx={{ ml: [3], overflow: "hidden" }}
                          >
                            <ArrowLong />
                          </Flex>
                        </Flex>
                      </MagicLink>
                    )}
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Container>
        </>
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    contactSection: allDatoCmsContactSection(
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        kicker
        title
        subtitle
        privacyPolicyDescription
        newsletterDescription
        link {
          ... on DatoCmsInternalLink {
            id: originalId
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
        }
      }
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      showContactSection
      content {
        ... on DatoCmsCatalogueDownload {
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsBackgroundImageAndBlock {
          id
          blocks: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          align
          color
          model {
            apiKey
          }
        }
        ... on DatoCmsDoubleColumnList {
          id
          models {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          model {
            apiKey
          }
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          lighter
        }
        ... on DatoCmsProductsGallery {
          id
          model {
            apiKey
          }
          products {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
        }
        ... on DatoCmsFullImageTitle {
          id
          title
          image {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAgentCollection {
          id
          agents {
            originalId
            country
            company
            agents {
              agentFullName
              email
              phone
            }
            coordinates {
              latitude
              longitude
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndTextCollection {
          id
          smallImage
          align
          collection: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndTextCollectionCustom {
          id
          smallImage
          align
          collection: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsNumbersCollection {
          id
          title
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          rightAligned
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          id
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCategory {
          id
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsLocationsMap {
          id
          locations {
            originalId
            id
            name
            addressCountry
            addressRegion
            addressLocality
            email
            faxNumber
            coordinates {
              latitude
              longitude
            }
            postalCode
            streetAddress
            telephone
            locale
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          id
          ...ImageGallery
        }
        ... on DatoCmsTable {
          kicker
          title
          table
          model {
            apiKey
          }
        }
        ... on DatoCmsDocumentCollection {
          id
          title
          showPublicationDate
          documents {
            title
            subtitle
            documents {
              url
              title
              format
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          title
          subtitle
          body {
            blocks
            links
            value
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsReferencesBlock {
          category
          referenceContent: content {
            title
            image {
              gatsbyImageData(
                width: 1480
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    kicker
    subtitle
    image {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    gallery {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsIconAndText {
          id: originalId
          icon {
            url
            gatsbyImageData(
              width: 100
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          text
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
          }
        }
      }
      value
    }
    link {
      ... on DatoCmsInternalLink {
        id
        anchor
        locale
        model {
          apiKey
        }
        link {
          ... on DatoCmsBlogPage {
            ...BlogDetails
          }
          ... on DatoCmsPage {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
          }
          ... on DatoCmsArticle {
            ...ArticleDetails
            ...ArticleAllSlugLocales
          }
          ... on DatoCmsArticleCategory {
            ...ArticleCategoryDetails
            ...ArticleCategoryAllSlugLocales
          }
        }
      }
      ... on DatoCmsExternalLink {
        id
        anchor
        url
        model {
          apiKey
        }
      }
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    slug
    root
    showContactSection
    model {
      apiKey
    }
    heroImage {
      desktop: gatsbyImageData(
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fit: "crop", ar: "16:8" }
      )
      mobile: gatsbyImageData(
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fit: "crop", ar: "4:5", h: "840" }
      )
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
