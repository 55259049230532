import React from "react"
import { Box, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const TitleAndBody = ({ content }) => {
  const { title, body, kicker } = content
  return (
    <Box sx={{ position: "relative", my: [3, 6] }}>
      <Container sx={{py:[0,4]}}>
        <Grid columns={[1, 1, "570px"]} gap={[0,0]} sx={{justifyContent:"center"}}>
          <Box>
            <Text variant="kicker">{kicker}</Text>
            <Heading
              as="h2"
              variant="h2"
              sx={{
                fontFamily: "body",
                letterSpacing: ".5px",
                lineHeight: "1.2",
                fontWeight: "300",
              }}
            >
              {title}
            </Heading>
            <Box
              sx={{
                mb: [5],
                mt: [3],
                border: "1px solid",
                borderColor: "Grey",
              }}
            ></Box>
            <Box>
              <RichContentStructuredText text={body} />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default TitleAndBody
