import React from "react"
import { Box, Text, Heading, Flex, Grid, Container } from "@theme-ui/components"
import BlockParallax from "../blockParallax"
import { GatsbyImage } from "gatsby-plugin-image"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import ArrowLong from "../../images/long-arrow.inline.svg"

const ImageAndTextCollection = ({ items, smallImage, align }) => {
  return (
    <Box sx={{ position: "relative", my: [0, 6] }}>
      <Container variant={!smallImage && "customFW"} sx={{px:[0,4, !smallImage ? 8 : 4 ]}}>
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <Box
              sx={{
                mt: index === 0 ? [0] : [0,6],
              }}
            >
              <ItemCompoent
                item={item}
                smallImage={smallImage}
                align={align}
                index={index}
              />
            </Box>
          ))}
      </Container>
    </Box>
  )
}

const ItemCompoent = ({ item, smallImage, align, index }) => (
  <Flex sx={{ position: "relative", justifyContent:"center", flexWrap: "wrap" }}>
      <Box
        sx={{
          px: [3,3, 0],
          py:[4,0],
          maxWidth: "570px",
          gridColumn: !smallImage && [1,1,index % 2 !== 0 ? 2 : 1],
          gridRow: !smallImage && ["auto","auto",1],
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text variant="kicker">{item.kicker}</Text>
        <Heading
              as="h2"
              variant="h2"
              sx={{
                fontFamily: "body",
                letterSpacing: ".5px",
                lineHeight: "1.2",
                fontWeight: "300",
              }}
            >
              {item.title}
            </Heading>
        {item.body && (
          <Box sx={{}}>
            <RichContentStructuredText text={item.body} />
          </Box>
        )}

        {item.link && (
          <MagicLink
            item={item.link}
            variant="links.simplePrimary"
            sx={{ mt: [0,3], mb:[6,0] }}
          >
            <Flex sx={{ alignItems: "center" }}>
              {item.link.anchor}
              <Flex sx={{ ml: [3],overflow:"hidden" }}>
                <ArrowLong />
              </Flex>
            </Flex>
          </MagicLink>
        )}
      </Box>
      <Box
        className="bg-image"
        sx={{
          gridColumn: !smallImage && [1,1,index % 2 !== 0 ? 3 : 2],
          gridRow: !smallImage && ["auto","auto",1],
          height: [smallImage && "222px"],
          width: ["100%","100%",  "450px"],
          maxWidth: ["100%","100%","450px"],
          maxHeight: ["100%","100%","450px"],
          minWidth: ["100%","100%","222px"],
          minHeight: ["100%",smallImage ? "222px" : "380px", smallImage ? "222px" : "380px" ],
          zIndex: 1,
          ".gatsby-image-wrapper": {
            height: "100%",
            width: "100%",
            img: {
              objectFit:"contain!important",
              height:"100%",
              objectPosition:"center center"
            },
          },
          overflow: "hidden",
        }}
      >
        {item.image ? (
          <GatsbyImage
            image={item.image.gatsbyImageData}
            alt={item.image.gatsbyImageData}
          />
        ) : (
          <Box
            sx={{
              width: ["100%"],
              minHeight: ["300px","100%"],
              backgroundColor: "secondaryLight",
            }}
          ></Box>
        )}
      </Box>
  </Flex>
)

export default ImageAndTextCollection
