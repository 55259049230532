import React, { useState } from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../assets/style/swiper-bundle.min.css"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"
import BlockParallax from "../blockParallax"
import { GatsbyImage } from "gatsby-plugin-image"
import RichContentStructuredText from "../richContentStructuredText"
import { useBreakpointIndex } from "@theme-ui/match-media"
import ArrowLong from "../../images/long-arrow.inline.svg"
import { ChevronLeft, ChevronRight } from "react-feather"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const ProductsGallery = ({ title, products }) => {
  // console.log(products)
  return (
    <Box sx={{ position: "relative" }}>
      <Container variant="customFW">
        <Grid columns={[1, 1, 2]}>
          {products.map((product, index) => (
            <Box sx={{ mt: index % 2 && [0, 0, 6] }}>
              <PageThumb product={product} />
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const PageThumb = ({ product }) => {
  const breakpointIndex = useBreakpointIndex()
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  return (
    <Flex
      gap={[0]}
      sx={{
        flexDirection: "column",
        height: "100%",
        zIndex: 0,
        ".swiper": {
          height: "100%",
        },
        ".swiper-wrapper": {
          minHeight: "100%",
        },
        ".swiper-container": {
          height: "100%",
          width: "100%",
          pb: [0],
        },
        ".swiper-slide": {
          height: "auto",
        },
        ".swiper-pagination": {
          mb: [4],
        },
        ".swiper-pagination-bullet ": {
          backgroundColor: "light",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <SimpleReactLightbox>
          <SRLWrapper options={{caption :{showCaption:false}}}>
            <Swiper
              id={product.id}
              slidesPerView={1}
              grabCursor={false}
              spaceBetween={0}
              draggable={false}
              pagination={
                breakpointIndex <= 2 && {
                  clickable: true,
                  dynamicBullets: true,
                }
              }
              navigation={{ prevEl, nextEl }}
            >
              {product.gallery &&
                product.gallery.map((image, index) => (
                  <SwiperSlide key={product.originalId + index}>
                    <Box
                      className="bg-image"
                      sx={{
                        height: "auto",
                        width: "100%",
                        maxWidth: ["865px"],
                        maxHeight: ["800px"],
                        backgroundColor: "light",
                        ".gatsby-image-wrapper": {
                          width: "100%",
                          height: "auto",
                          maxWidth: ["865px"],
                          maxHeight: ["600px"],
                          minHeight:["300px","600px"],
                          cursor:"pointer"
                        },
                      }}
                    >
                      {image && (
                        <GatsbyImage
                          image={image.gatsbyImageData}
                          alt={image.gatsbyImageData}
                        />
                      )}
                    </Box>
                  </SwiperSlide>
                ))}
            </Swiper>
          </SRLWrapper>
        </SimpleReactLightbox>
        {product.gallery && product.gallery.length > 1 && (
          <>
            <Box
              as="div"
              sx={{
                position: "absolute",
                top: "50%",
                left: "0",
                transform: "translateY(-50%)",
                zIndex: 2,
              }}
              ref={node => setPrevEl(node)}
            >
              <Flex
                sx={{
                  p: [2, 3],
                  py: [2, 3],
                  ml: [2],
                  backgroundColor: "secondary",
                  cursor: "pointer",
                  alignItems: "center",
                  alignContent: "center",
                  minWidth: ["32px", "48px", "73px"],
                  minHeight: ["32px", "48px", "73px"],
                  svg: {
                    transform: "rotate(180deg)",
                    width: ["24px", "34px"],
                  },
                  "svg g": {
                    stroke: "light",
                  },
                }}
              >
                <ArrowLong color="white" size={"24"} />
              </Flex>
            </Box>

            <Box
              as="div"
              sx={{
                position: "absolute",
                top: "50%",
                right: "0",
                transform: "translateY(-50%)",
                zIndex: 2,
              }}
              className="nextEl"
              ref={node => setNextEl(node)}
            >
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  p: [2, 3],
                  py: [2, 3],
                  mr: [2],
                  backgroundColor: "secondary",
                  cursor: "pointer",
                  minWidth: ["32px", "48px", "73px"],
                  minHeight: ["32px", "48px", "73px"],
                  svg: {
                    width: ["24px", "34px"],
                  },
                  "svg g": {
                    stroke: "light",
                  },
                }}
              >
                <ArrowLong color="white" size={"24"} />
              </Flex>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ pt: [2, 2, 4], pb: [2, 2, 0] }}>
        <Heading
          as="h2"
          sx={{
            fontSize: [4],
            fontFamily: "body",
            fontWeight: "300",
            letterSpacing: ".5",
            lineHeight: "1.2",
            color: "secondary",
            mb: [2],
          }}
        >
          {product.title}
        </Heading>
        <Box
          sx={{
            p: {
              color: "secondary",
              fontSize: [2],
              letterSpacing: "0.25",
              lineHeight: "1.333",
            },
          }}
        >
          <RichContentStructuredText text={product.body} />
        </Box>
      </Box>
    </Flex>
  )
}

export default ProductsGallery
