import React, { useState } from "react"
import { i18nContext } from "../../context/i18nContext"
import axios from "axios"
import { keyframes } from "@emotion/react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Label,
  Link,
} from "@theme-ui/components"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Textarea, Checkbox } from "theme-ui"
import { InboundLink, OutboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import ArrowLong from "../../images/long-arrow.inline.svg"

const slideInLeftCenter = keyframes`
  0% {
    transform: translateX(0%);
  }
  33% {
    transform: translateX(100%);
    visibility:hidden;
  }
  66% {
    transform: translateX(-100%);
    visibility:hidden;
  }
  100% {
    transform: translateX(0%);
    visibility:visibile;
  }
`

const CatalogueDownload = ({ page }) => {
  const [showModal, setShowModal] = useState(false)

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    company:"",
    product: page.id.substring(
      page.id.indexOf("-") + 1,
      page.id.lastIndexOf("-")
    ),
    agreementNewsLetter: false,
    agreementPrivacyCookies: false
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  const addCatalogueData = async event => {
    event.preventDefault()
    const data = formData

    console.log(formData)
    setLoading(true)
    console.log(data)
    axios
      .post("/.netlify/functions/sendCatalogueData", data)
      .then(function (response) {
        setSuccess(true)
        setLoading(false)
        console.log("response", response)

        const url = response.data
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `Catalogo.pdf`)
        link.setAttribute("target", `_blank`)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(function (error) {
        console.log("error", error)
        setLoading(false)
      })
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <>
          <Box sx={{ position: "relative", mt: [-3, -9], mb: [3, 6] }}>
            <Container sx={{ py: [0, 4] }}>
              <Grid
                columns={[1, 1, "570px"]}
                gap={[0, 0]}
                sx={{ justifyContent: "center" }}
              >
                <Box
                  onClick={() => setShowModal(true)}
                  sx={{
                    cursor: "pointer",
                    color: "primary",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {t.downloadCatalogo}
                </Box>
              </Grid>
            </Container>
          </Box>
          {showModal && (
            <Box
              sx={{
                py: [4, 6],
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 9999,
                backgroundColor: "rgba(0,0,0,.5)",
              }}
              onClick={e => {
                setShowModal(false)
                document.getElementById("subscribe").reset()
                setSuccess(null)
              }}
            >
              <Container name="contactForm" sx={{ py: [0, 4] }}>
                <Box
                  as="form"
                  id="subscribe"
                  onSubmit={addCatalogueData}
                  sx={{
                    my: [4],
                    p: [4],
                    maxWidth: "600px",
                    margin: "0 auto",
                    backgroundColor: "white",
                  }}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <Box
                    sx={{
                      pb: [4],
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      onClick={() => {
                        console.log(22)
                        setShowModal(false)
                        document.getElementById("subscribe").reset()
                        setSuccess(null)
                      }}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Hamburger show={true} />
                    </Box>
                  </Box>
                  <Box>
                    <Grid
                      sx={{
                        flexWrap: "wrap",
                        flexDirection: "column",
                      }}
                      gap={[0, 0, 0, 0]}
                    >
                      {success === null && (
                        <>
                          <Flex sx={{ flexWrap: "wrap" }}>
                            <Box
                              sx={{
                                pr: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    firstName: e.target.value,
                                  }))
                                }
                                name="full name"
                                placeholder={t.formInputTexts.firstName}
                                variant={"inputs.secondary"}
                                required
                              />
                            </Box>
                            <Box
                              sx={{
                                pl: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    lastName: e.target.value,
                                  }))
                                }
                                name="company"
                                placeholder={t.formInputTexts.lastName}
                                variant={"inputs.secondary"}
                                required
                              />
                            </Box>
                          </Flex>
                          <Flex sx={{ flexWrap: "wrap" }}>
                            <Box
                              sx={{
                                pr: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    email: e.target.value,
                                  }))
                                }
                                name="email"
                                type="email"
                                placeholder="Email*"
                                variant={"inputs.secondary"}
                                required
                              />
                            </Box>
                            <Box
                              sx={{
                                pl: [0, 1, 1, 1],
                                pb: [3, 3, 3, 3],
                                width: ["100%", "50%"],
                              }}
                            >
                              <Input
                                onChange={e =>
                                  setFormData(prevState => ({
                                    ...prevState,
                                    company: e.target.value,
                                  }))
                                }
                                name="company"
                                placeholder={t.formInputTexts.company}
                                variant={"inputs.secondary"}
                                required
                              />
                            </Box>
                          </Flex>
                          <LabeledCheckbox
                            checkedCheckbox={e => {
                              setFormData(prevState => ({
                                ...prevState,
                                agreementPrivacyCookies: e,
                              }))
                            }}
                            required={true}
                          >
                            <Box>
                              <Text
                                variant="p"
                                sx={{
                                  fontSize: [0],
                                  color: "secondary",
                                  a: {
                                    color: "secondary",
                                  },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    t.locale == "it"
                                      ? `<p><span>* Acconsento a che i miei dati personali vengano utilizzati in accordo con la</span><span>&nbsp;</span><a href="/privacy-cookie-policy">privacy<span>&nbsp;</span><span>e</span><span>&nbsp;</span>cookie<span>&nbsp;</span><span>policy</span></a></p>`
                                      : `<p><span>* I consent to my personal data being used in accordance with the</span><span>&nbsp;</span><a href="/privacy-cookie-policy">privacy<span> and</span><span>&nbsp;</span>cookie<span>&nbsp;</span><span>policy</span></a></p>`,
                                }}
                              />
                            </Box>
                          </LabeledCheckbox>
                          <LabeledCheckbox
                            checkedCheckbox={e =>
                              setFormData(prevState => ({
                                ...prevState,
                                agreementNewsLetter: e,
                              }))
                            }
                          >
                            <Box>
                              <Text
                                variant="p"
                                sx={{
                                  fontSize: [0],
                                  a: {
                                    color: "secondary",
                                  },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    t.locale == "it"
                                      ? `<p>Acconsento all’uso dei miei dati personali per essere aggiornato sui nuovi arrivi, prodotti in esclusiva e per le finalità di marketing diretto correlate ai servizi offerti e ricevere proposte in linea con i miei interessi attraverso l’analisi dei miei precedenti acquisti</p>`
                                      : `<p>I consent to the use of my personal data to be updated on new arrivals, exclusive products and for direct marketing purposes related to the services offered and to receive proposals in line with my interests through the analysis of my previous purchases.</p>`,
                                }}
                              />
                            </Box>
                          </LabeledCheckbox>
                          <Box>
                            <Button
                              type="submit"
                              title={
                                !formData.agreementPrivacyCookies
                                  ? "Acconsenti al trattamenti dei dati personali"
                                  : ""
                              }
                              sx={{
                                cursor: "pointer",
                                mt: [3, 3, 3, 3],
                                opacity:
                                  !formData.agreementPrivacyCookies && 0.7,
                              }}
                              variant={"links.primary"}
                              disabled={loading}
                            >
                              <Flex
                                sx={{
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {loading ? t.loading : t.submitDownload}
                                <Flex
                                  className="arrowIcon"
                                  sx={{
                                    ml: !loading && [3],
                                    overflow: "hidden",
                                    svg: {
                                      animation:
                                        loading &&
                                        `${slideInLeftCenter} 1s linear .25s infinite!important`,
                                      width: "100%",
                                    },
                                  }}
                                >
                                  <ArrowLong />
                                </Flex>
                              </Flex>
                            </Button>
                          </Box>
                        </>
                      )}

                      <Box>
                        {success === true ? (
                          <Flex sx={{ maxWidth: "600px" }}>
                            <Heading
                              sx={{ my: [4], color: "secondary" }}
                              as="h4"
                            >
                              {t.submitDownloadSucc}
                            </Heading>
                          </Flex>
                        ) : (
                          success === false && (
                            <Flex sx={{ maxWidth: "600px" }}>
                              <Heading
                                sx={{ my: [4], color: "secondary" }}
                                as="h4"
                              >
                                {t.submitDownloadErr}
                              </Heading>
                            </Flex>
                          )
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </Box>
          )}
        </>
      )}
    </i18nContext.Consumer>
  )
}

const Hamburger = ({ show }) => {
  return (
    <Box
      className={show ? "show" : ""}
      sx={{
        width: "25px",
        "&:before,&:after,& div": {
          borderRadius: "sm",
          content: "''",
          backgroundColor: "primary",
          display: "block",
          height: "3px",
          my: [1],
          transition: "transform .15s ease-in-out, opacity .0s ease-in-out",
        },
        "&.show:before": {
          transform: "translateY(8px) rotate(135deg)",
        },
        "&.show:after": {
          transform: "translateY(-6px) rotate(-135deg)",
        },
        "&.show div": {
          transform: "scale(0)",
        },
      }}
    >
      <Box></Box>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "secondary",
        "input:checked~.css-kydphz": {
          color: "secondary",
        },
        svg: {
          color: "secondary",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: "secondary",
          "input:checked~&": {
            color: "secondary",
          },
        }}
        checked={checked}
        onChange={(e) => {
          checkedCheckbox(e.target.checked)
          setChecked(e.target.checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default CatalogueDownload
