import React from "react"
import { Box, Heading, Text, Container } from "theme-ui"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Check, X } from "react-feather"

const ProductTable = ({ kicker, title, table }) => {
  const parsedTable = JSON.parse(table)

  return (
    <Box sx={{ textAlign: "center", position:"relative" }}>
      <Container
        variant="customFW"
        sx={{
          table: {
            width: "100%",
            tableLayout: "fixed",
            th: {
              pb: [4],
              fontSize: [3],
              fontWeight: "500",
              letterSpacing: ".35px",
              lineHeight: "1.19",
              div: { maxWidth: "275px" },
            },
            tr: {
              td: {
                "&.pivoted": {
                  pb: [6],
                },
                "&:nth-child(1)": {
                  textAlign: "left",
                  fontWeight: "300",
                },
                fontSize: [1],
                div: { maxWidth: "275px" },
                py: [3],
                fontWeight: "500",
                letterSpacing: ".25px",
                lineHeight: "1.1667",
              },
            },
          },
        }}
      >
        <Text variant="kicker">{kicker}</Text>
        <Heading
          variant="h2"
          as="h2"
          sx={{
            fontWeight: "500",
          }}
        >
          {title}
        </Heading>
        <Table
          style={{
            borderCollapse: "collapse",
          }}
        >
          <Thead>
            <Tr>
              {parsedTable.columns.map(column => (
                <Th>
                  <Box>{column}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {parsedTable.data.map(row => (
              <Tr
                style={{
                  borderBottom: "1px solid #171717",
                }}
              >
                {Object.values(row).map(function (key, value) {
                  return (
                    <Td>
                      <Box>
                        {key === "Sì" || key === "Si" ? (
                          <Check color="#4CAF50" />
                        ) : key === "No" ? (
                          <X color="#FF1616" />
                        ) : (
                          key
                        )}
                      </Box>
                    </Td>
                  )
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Container>
    </Box>
  )
}

export default ProductTable
