import React from "react"
import { Box, Heading, Text, Container, Grid, Flex } from "theme-ui"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Check, X } from "react-feather"
import { GatsbyImage } from "gatsby-plugin-image"

const ReferencesBlock = ({ title, references }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Container variant="customFW">
        <Grid columns={["1fr", "1fr", "1fr", "300px 1fr"]} gap={[3, 8]}>
          <Heading
            variant="h3"
            as="div"
            sx={{
              h2: {
                fontSize: [4, 5],
                fontWeight: "normal",
              },
              color: "secondary",
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Grid
            gap={[3,5]}
            columns={[
              "1fr 1fr ",
              "1fr 1fr 1fr",
              "1fr 1fr 1fr",
              "1fr 1fr 1fr 1fr",
            ]}
            sx={{
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center"
            }}
          >
            {references.map(reference => (
              <Box
                sx={{
                    height:"100%",
                    width:"100%",
                    maxWidth:"60%",
                    maxHeight:["60px","100px"],
                  ".gatsby-image-wrapper": {
                    width:"100%",
                    height:"100%",
                    position: "relative",
                    img: {
                      height: "auto",
                      width:"auto",
                      maxHeight:"100%",
                      maxWidth:"100%",
                      top:"50%",
                      left:"50%",
                      transform:"translate(-50%,-50%)",
                      objectFit: "contain!important"
                    },
                  },
                }}
              >
                <GatsbyImage
                  title={reference.title}
                  image={reference.image.gatsbyImageData}
                  alt={reference.image.gatsbyImageData}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ReferencesBlock
