import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../assets/style/swiper-bundle.min.css"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"
import BlockParallax from "../blockParallax"
import { GatsbyImage } from "gatsby-plugin-image"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const PageCarousel = ({ title, pages }) => {
  // console.log(pages)
  return (
    <Box sx={{ position: "relative" }}>
      <Container>
        <Grid columns={[1, 2, 3]}>
          {pages.map(page => (
            <PageThumb page={page} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const PageThumb = ({ page }) => {
  const index = useBreakpointIndex()
  return (
  <InboundLink
    to={getPagePath(page, page.locale)}
    sx={{ textDecoration: "none" }}
  >
    {console.log("page", page)}
    <Flex
      sx={{
        height: ["24rem"],
        backgroundColor: "secondary",
        padding: 4,
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: ["100%"],
          left: 0,
          top: 0,
          width: ["100%"],
          ".gatsby-image-wrapper": { height: "100%" },
          overflow: "hidden",
        }}
      >
        <BlockParallax
          style={{ width: "100%", height: "130%" }}
          translateY={[-20, 10]}
        >
          {page.heroImage ? (
            <GatsbyImage
              image={index > 2 ? page.heroImage.mobile : page.heroImage.mobile} 
              alt={index > 2 ? page.heroImage.mobile : page.heroImage.mobile} 
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                minHeight: "890px",
                backgroundColor: "secondaryLight",
              }}
            ></Box>
          )}
        </BlockParallax>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
            p: [3],
          }}
        >
          <Heading
            as="h2"
            variant="h3"
            sx={{ fontFamily: "heading", margin: 0, color: "light" }}
          >
            {page.title}
          </Heading>
        </Box>
      </Box>
    </Flex>
  </InboundLink>
)}

export default PageCarousel
